import request from "@/utils/request";

/**
 * Get keyword with search feature.
 *
 * @param {object} [params]
 * @param {string} params.search
 */
export function getKeywords(params) {
  return request({
    url: "api/v1/keywords",
    method: "GET",
    params,
  });
}

/**
 * Get keyword with pagination feature.
 *
 * @param {object} [params]
 * @param {number} params.limit
 * @param {number} params.page
 * @param {string} params.search
 */
export function getKeywordsPagination(params) {
  return request({
    url: "api/v1/keywords/page",
    method: "GET",
    params,
  });
}

/**
 * Get keyword.
 *
 * @param {number} keyword_id
 */
export function getKeyword(keyword_id) {
  return request({
    url: `api/v1/keywords/${keyword_id}`,
    method: "GET",
  });
}

/**
 * Create keyword.
 *
 * @param {object} data
 * @param {string} data.name_zh
 * @param {string} data.name_en
 */
export function createKeyword(data) {
  return request({
    url: "api/v1/keywords",
    method: "POST",
    data,
  });
}

/**
 * Update keyword.
 *
 * @param {number} keyword_id
 * @param {object} data
 * @param {string} data.name_zh
 * @param {string} data.name_en
 */
export function updateKeyword(keyword_id, data) {
  return request({
    url: `api/v1/keywords/${keyword_id}`,
    method: "PATCH",
    data,
  });
}

/**
 * Delete keyword.
 *
 * @param {number} keyword_id
 */
export function deleteKeyword(keyword_id) {
  return request({
    url: `api/v1/keywords/${keyword_id}`,
    method: "DELETE",
  });
}
