import request from "@/utils/request";
import axios from "axios";
import store from "@/store";

/**
 * Get works with search features.
 *
 * @param {object} [params]
 * @param {string} params.search
 */
export function getWorks(params) {
  return request({
    url: "api/v1/works",
    method: "GET",
    params,
  });
}

/**
 * Get works with pagination and search features.
 *
 * @param {object} [params]
 * @param {number} params.limit
 * @param {number} params.page
 * @param {string} [params.search]
 */
export function getWorksPagination(params) {
  return request({
    url: "api/v1/works/page",
    method: "GET",
    params,
  });
}

/**
 * Get work.
 *
 * @param {string} work_id
 * @param {object} params
 * @param {object} params.relation
 */
export function getWork(work_id, params) {
  return request({
    url: `api/v1/works/${work_id}`,
    method: "GET",
    params,
  });
}

/**
 * Create work without file part.
 *
 * @param {object} data
 * @param {string} data.title_zh
 * @param {string} data.title_en
 * @param {string} data.description_zh
 * @param {string} data.description_en
 * @param {string} data.dimension_zh
 * @param {string} data.dimension_en
 * @param {boolean} data.active
 * @param {string} [data.media]
 * @param {string} data.year
 * @param {number} data.location work_location_id
 * @param {string} data.proposal_zh
 * @param {string} data.proposal_en
 * @param {boolean} data.active
 * @param {string} [data.note_zh]
 * @param {string} [data.note_en]
 * @param {string} [data.file_url]
 */
export function createWork(data) {
  return request({
    url: "api/v1/works",
    method: "POST",
    data,
  });
}

/**
 * Upload image with description and file.
 *
 * @param {string} work_id
 * @param {object} [data]
 * @param {File} [data.photo_1]
 * @param {string} [data.photo_1_d_en]
 * @param {string} [data.photo_1_d_zh]
 * @param {File} [data.photo_2]
 * @param {string} [data.photo_2_d_en]
 * @param {string} [data.photo_2_d_zh]
 * @param {File} [data.photo_3]
 * @param {string} [data.photo_3_d_en]
 * @param {string} [data.photo_3_d_zh]
 * @param {File} [data.photo_4]
 * @param {string} [data.photo_4_d_en]
 * @param {string} [data.photo_4_d_zh]
 * @param {File} [data.photo_5]
 * @param {string} [data.photo_5_d_en]
 * @param {string} [data.photo_5_d_zh]
 */
export function uploadWorkPhoto(work_id, data) {
  return request({
    url: `api/v1/works/upload/photo/${work_id}`,
    method: "PATCH",
    data,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

/**
 * Delete single work photo.
 *
 * @param {string} work_id
 * @param {object} data
 * @param {string} data.photo_name
 */
export function deleteWorkPhoto(work_id, data) {
  return request({
    url: `api/v1/works/upload/photo/${work_id}`,
    method: "DELETE",
    data,
  });
}

/**
 * Upload work file.
 *
 * @param {string} work_id
 * @param {object} [data]
 * @param {File} data.file
 */
export function uploadWorkFile(work_id, data) {
  const CancelToken = axios.CancelToken;
  let cancel;
  const queue = store.getters.queue;

  // behind the scene, update the queue
  store.dispatch("upload/setQueue", queue).then(console.log);
  return request({
    url: `api/v1/works/upload/file/${work_id}`,
    method: "PATCH",
    headers: { "Content-Type": "multipart/form-data" },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
      store
        .dispatch("upload/cancelProgress", { cancel, queue })
        .then(console.log, console.error);
    }),
    data,
    onUploadProgress: function (progressEvent) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      store
        .dispatch("upload/addProgress", {
          queue,
          progress: percentCompleted,
        })
        .then(console.log, console.error);

      if (percentCompleted >= 100) {
        store
          .dispatch("upload/removeProgress", queue)
          .then(console.log, console.error);
      }
    },
  });
}

/**
 * Delete work file.
 *
 * @param {string} work_id
 */
export function deleteWorkFile(work_id) {
  return request({
    url: `api/v1/works/upload/file/${work_id}`,
    method: "DELETE",
  });
}

/**
 * Update work.
 *
 * @param {string} work_id
 * @param {object} data
 * @param {string} data.title_zh
 * @param {string} data.title_en
 * @param {string} data.description_zh
 * @param {string} data.description_en
 * @param {string} data.dimension_zh
 * @param {string} data.dimension_en
 * @param {boolean} data.active
 * @param {string} [data.media]
 * @param {string} data.year
 * @param {number} data.location work_location_id
 * @param {string} data.proposal_zh
 * @param {string} data.proposal_en
 * @param {boolean} data.active
 * @param {string} [data.note_zh]
 * @param {string} [data.note_en]
 * @param {string} [data.file_url]
 */
export function updateWork(work_id, data) {
  return request({
    url: `api/v1/works/${work_id}`,
    method: "PATCH",
    data,
  });
}

/**
 * Delete work.
 *
 * @param {string} work_id
 */
export function deleteWork(work_id) {
  return request({
    url: `api/v1/works/${work_id}`,
    method: "DELETE",
  });
}

/**
 * Create work and work material relationship.
 *
 * @param {string} work_id
 * @param {number} work_material_id
 */
export function createWorkMaterial(work_id, work_material_id) {
  return request({
    url: `api/v1/works/${work_id}/materials/${work_material_id}`,
    method: "POST",
  });
}

/**
 * Create work and work materials relationship.
 *
 * @param {string} work_id
 * @param {object} data
 * @param {number[]} data.work_material_id_group
 */
export function createWorkMaterials(work_id, data) {
  return request({
    url: `api/v1/works/${work_id}/materials`,
    method: "POST",
    data,
  });
}

/**
 * Delete work and work material relationship.
 *
 * @param {string} work_id
 * @param {number} work_material_id
 */
export function deleteWorkMaterial(work_id, work_material_id) {
  return request({
    url: `api/v1/works/${work_id}/materials/${work_material_id}`,
    method: "DELETE",
  });
}

/**
 * Get work and keyword relationship.
 *
 * @param {string} work_id
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.limit
 * @param {string} params.search
 */
export function getWorkKeywords(work_id, params) {
  return request({
    url: `api/v1/works/${work_id}/keywords`,
    method: "GET",
    params,
  });
}

/**
 * Create work and keyword relationship.
 *
 * @param {string} work_id
 * @param {object} data
 * @param {number[]} data.keyword_id_group
 */
export function createWorkKeywords(work_id, data) {
  return request({
    url: `api/v1/works/${work_id}/keywords`,
    method: "POST",
    data,
  });
}

/**
 * Delete work and keyword relationship.
 *
 * @param {string} work_id
 * @param {number} keyword_id
 */
export function deleteWorkKeywords(work_id, keyword_id) {
  return request({
    url: `api/v1/works/${work_id}/keywords/${keyword_id}`,
    method: "DELETE",
  });
}

/**
 * Create work and group relationship.
 *
 * @param {string} work_id
 * @param {object} data
 * @param {number[]} data.group_id_group
 */
export function createWorkGroups(work_id, data) {
  return request({
    url: `api/v1/works/${work_id}/groups`,
    method: "POST",
    data,
  });
}

/**
 * Delete work and artist relationship.
 *
 * @param {string} work_id
 * @param {string} artist_id
 */
export function deleteWorkArtist(work_id, artist_id) {
  return request({
    url: `api/v1/works/${work_id}/artists/${artist_id}`,
    method: "DELETE",
  });
}

/**
 * Create work and artist relationship.
 *
 * @param {string} work_id
 * @param {object} data
 * @param {string[]} data.artist_id_group
 */
export function createWorkArtists(work_id, data) {
  return request({
    url: `api/v1/works/${work_id}/artists`,
    method: "POST",
    data,
  });
}
