import request from "@/utils/request";

/**
 * Get artists with search feature.
 *
 * @param {object} [params]
 * @param {string} [params.search]
 */
export function getArtists(params) {
  return request({
    url: "api/v1/artists",
    method: "GET",
    params,
  });
}

/**
 * Get artists with pagination with feature.
 *
 * @param {object} [params]
 * @param {number} params.limit
 * @param {number} params.page
 */
export function getArtistsPagination(params) {
  return request({
    url: "api/v1/artists/page",
    method: "GET",
    params,
  });
}

/**
 * Get artist.
 *
 * @param {string} artist_id
 * @param {object} [params]
 * @param {boolean} [params.relation]
 */
export function getArtist(artist_id, params) {
  return request({
    url: `api/v1/artists/${artist_id}`,
    method: "GET",
    params,
  });
}

/**
 * Create artist.
 *
 * @param {object} data
 * @param {File} data.avatar
 * @param {string} data.name_zh
 * @param {string} data.name_en
 * @param {string} data.introduction_zh
 * @param {string} data.introduction_en
 */
export function createArtist(data) {
  return request({
    url: "api/v1/artists",
    method: "POST",
    data,
  });
}

/**
 * Update artist.
 *
 * @param {string} artist_id
 * @param {object} data
 * @param {boolean} data.active
 * @param {File} [data.avatar]
 * @param {string} data.name_zh
 * @param {string} data.name_en
 * @param {string} data.introduction_zh
 * @param {string} data.introduction_en
 */
export function updateArtist(artist_id, data) {
  return request({
    url: `api/v1/artists/${artist_id}`,
    method: "PATCH",
    data,
  });
}

/**
 * Update artist active.
 *
 * @param {string} artist_id
 * @param {object} data
 * @param {boolean} data.active
 */
export function updateArtistActive(artist_id, data) {
  return request({
    url: `api/v1/artists/active/${artist_id}`,
    method: "PATCH",
    data,
  });
}

/**
 * Delete artist.
 *
 * @param {string} artist_id
 */
export function deleteArtist(artist_id) {
  return request({
    url: `api/v1/artists/${artist_id}`,
    method: "DELETE",
  });
}

/**
 * Get artist and keyword relationship.
 *
 * @param {string} artist_id
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.limit
 * @param {string} params.search
 */
export function getArtistKeywords(artist_id, params) {
  return request({
    url: `api/v1/artists/${artist_id}/keywords`,
    method: "GET",
    params,
  });
}

/**
 * Create artist and keyword relationship.
 *
 * @param {string} artist_id
 * @param {object} data
 * @param {number[]} data.keyword_id_group
 */
export function createArtistKeywords(artist_id, data) {
  return request({
    url: `api/v1/artists/${artist_id}/keywords`,
    method: "POST",
    data,
  });
}

/**
 * Delete artist and keyword relationship.
 *
 * @param {string} artist_id
 * @param {number} keyword_id
 */
export function deleteArtistKeywords(artist_id, keyword_id) {
  return request({
    url: `api/v1/artists/${artist_id}/keywords/${keyword_id}`,
    method: "DELETE",
  });
}

/**
 * Create artist and work relationship.
 *
 * @param {string} artist_id
 * @param {object} data
 * @param {number[]} data.work_id_group
 */
export function createArtistWorks(artist_id, data) {
  return request({
    url: `api/v1/artists/${artist_id}/works`,
    method: "POST",
    data,
  });
}

/**
 * Delete artist and work relationship.
 *
 * @param {string} artist_id
 * @param {string} work_id
 */
export function deleteArtistWork(artist_id, work_id) {
  return request({
    url: `api/v1/artists/${artist_id}/works/${work_id}`,
    method: "DELETE",
  });
}

/**
 * Create category in artist.
 *
 * @param {string} artist_id
 * @param {number} category_id
 */
export function addArtistCategory(group_id, category_id) {
  return request({
    url: `api/v1/artists/${group_id}/categories/${category_id}`,
    method: "POST",
  });
}

/**
 * Delete category in artist.
 *
 * @param {string} artist_id
 * @param {number} category_id
 */
export function deleteArtistCategory(artist_id, category_id) {
  return request({
    url: `api/v1/artists/${artist_id}/categories/${category_id}`,
    method: "DELETE",
  });
}
