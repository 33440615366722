<template>
  <div class="artist-detail-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Artist' }"
        >藝術家管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>藝術家內容</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <div class="flex-end" style="padding: 12px 0">
        <el-button type="info" @click="show_detail = !show_detail">{{
          !show_detail ? "內容展開" : "內容收起"
        }}</el-button>
      </div>
      <div
        class="content text-center"
        v-if="!show_detail"
        @click="show_detail = !show_detail"
      >
        內容過多，請點擊展開
      </div>

      <el-form :model="artist_form" label-position="top" v-show="show_detail">
        <el-form-item label="藝術家照片" prop="avatar">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            accept=".jpeg,.jpg,.png"
            disabled
          >
            <img
              v-if="preview_url"
              :src="preview_url"
              class="avatar"
              alt="photo"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="啟用" prop="active">
          <el-radio-group v-model="artist_form.active" size="small">
            <el-radio :label="true" :border="true" disabled>啟用</el-radio>
            <el-radio :label="false" :border="true" disabled>停用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="中文名稱" prop="name_zh">
          <el-input v-model="artist_form.name_zh" disabled></el-input>
        </el-form-item>
        <el-form-item label="英文名稱" prop="name_en">
          <el-input v-model="artist_form.name_en" disabled></el-input>
        </el-form-item>
        <el-form-item label="中文國籍名稱" prop="country_zh">
          <el-input v-model="artist_form.country_zh" disabled></el-input>
        </el-form-item>
        <el-form-item label="英文國籍名稱" prop="country_en">
          <el-input v-model="artist_form.country_en" disabled></el-input>
        </el-form-item>
        <el-form-item label="中文簡歷" prop="introduction_zh">
          <el-input
            type="textarea"
            v-model="artist_form.introduction_zh"
            :rows="5"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="英文簡歷" prop="introduction_en">
          <el-input
            type="textarea"
            v-model="artist_form.introduction_en"
            :rows="5"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="中文履歷" prop="resume_zh">
          <div ref="js-editor-resume_zh"></div>
        </el-form-item>
        <el-form-item label="英文履歷" prop="resume_en">
          <div ref="js-editor-resume_en"></div>
        </el-form-item>
        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="$router.push({ name: 'Artist' })"
              >回主頁</el-button
            >
            <el-button
              type="primary"
              @click="
                $router.push({
                  name: 'EditArtist',
                  params: { id: artistId },
                })
              "
              >編輯
            </el-button>
          </div>
        </el-form-item>
      </el-form>

      <artist-work-table
        :data="works"
        @get:artist="handleGetArtist"
      ></artist-work-table>

      <category-table
        :categories="categories"
        @get:artist="handleGetArtist"
      ></category-table>

      <keyword-table
        :data="keywords"
        title="藝術家關鍵字列表"
        :create-method="createArtistKeywords"
        :delete-method="deleteArtistKeywords"
        @get:keywords="handleGetArtist"
      ></keyword-table>
    </div>
  </div>
</template>

<script>
import {
  getArtist,
  createArtistKeywords,
  deleteArtistKeywords,
} from "@/api/artist";
import KeywordTable from "@/components/Keyword/KeywordTable.vue";
import ArtistWorkTable from "./components/ArtistWorkTable.vue";
import CategoryTable from "./components/CategoryTable.vue";
import E from "wangeditor";

export default {
  name: "DetailArtist",
  components: { KeywordTable, ArtistWorkTable, CategoryTable },
  data() {
    return {
      artist_form: {
        active: true,
        name_zh: "",
        name_en: "",
        country_zh: "",
        country_en: "",
        introduction_zh: "",
        introduction_en: "",
        resume_zh: "",
        resume_en: "",
      },
      keywords: [],
      works: [],
      categories: [],
      preview_url: "",
      show_detail: false,
      createArtistKeywords,
      deleteArtistKeywords,
    };
  },
  computed: {
    artistId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.handleGetArtist();
  },
  methods: {
    async handleGetArtist() {
      const {
        avatar,
        active,
        artist_en,
        artist_zh,
        keywords,
        works,
        category,
      } = await getArtist(this.artistId, { relation: true });

      this.preview_url = `${process.env.VUE_APP_IMAGE_API}`.endsWith("/")
        ? `${process.env.VUE_APP_IMAGE_API}${avatar}`
        : `${process.env.VUE_APP_IMAGE_API}/${avatar}`;
      this.artist_form.avatar = avatar;
      this.artist_form.active = active;
      this.artist_form.name_en = artist_en.name;
      this.artist_form.name_zh = artist_zh.name;
      this.artist_form.country_en = artist_en.country || "";
      this.artist_form.country_zh = artist_zh.country || "";
      this.artist_form.introduction_en = artist_en.introduction || "";
      this.artist_form.introduction_zh = artist_zh.introduction || "";
      this.artist_form.resume_en = artist_en.resume || "";
      this.artist_form.resume_zh = artist_zh.resume || "";
      this.keywords = keywords;
      this.works = works;
      this.categories = category;

      this.handleInitEditor();
    },
    handleInitEditor() {
      Object.keys(this.$refs).forEach((ref) => {
        if (ref.startsWith(["js-editor"])) {
          const editor = new E(this.$refs[ref]);
          const key = ref.replace("js-editor-", "");

          // using base64 to store image
          editor.config.uploadImgShowBase64 = true;
          // disable online image url
          editor.config.showLinkImg = false;
          // change language to en
          editor.config.lang = "en";

          // import i18n
          editor.i18next = window.i18next;

          editor.config.placeholder = "請輸入內容";
          editor.config.menus = [
            "fontSize",
            "bold",
            "head",
            "link",
            "italic",
            "strikeThrough",
            "underline",
            "image",
          ];

          editor.create();

          editor.disable();

          editor.txt.html(this.artist_form[key]);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.artist-detail-block {
  .avatar {
    max-width: 800px;
  }

  .content {
    margin: 12px 0;
    padding: 24px 0;
    border: 1px solid #606266;
    border-radius: 12px;
    color: var(--primary-gray-color);
    font-size: 14px;
    cursor: pointer;

    &:hover {
      border: 1px solid #409eff;
      color: #409eff;
    }
  }

  ::v-deep .w-e-content-preview {
    background-color: #f5f7fa;
    cursor: not-allowed;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #c0c4cc;
    }
  }
}
</style>
