<template>
  <div class="category-table-component">
    <table-title>
      <p>
        類別列表: <span class="unit">數量：{{ total }}</span>
      </p>

      <el-button type="success" @click="dialogVisible.create = true"
        >新增類別</el-button
      >
    </table-title>

    <el-table :data="categories">
      <el-table-column label="ID" prop="category_id" sortable></el-table-column>
      <el-table-column
        label="中文名稱"
        prop="name_zh"
        sortable
      ></el-table-column>
      <el-table-column
        label="英文名稱"
        prop="name_en"
        sortable
      ></el-table-column>

      <el-table-column label="編輯" fixed="right" width="100">
        <template v-slot="{ row }">
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="handleDelete(row.category_id)"
            >刪除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div v-if="dialogVisible.create">
      <create-category-dialog
        :dialog-visible="dialogVisible.create"
        @close:dialog="handleClose"
      ></create-category-dialog>
    </div>
  </div>
</template>

<script>
import CreateCategoryDialog from "@/views/artist/components/CreateCategoryDialog";
import { deleteArtistCategory } from "@/api/artist";

export default {
  name: "CategoryTable",
  components: { CreateCategoryDialog },
  props: {
    categories: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: {
        create: false,
      },
    };
  },
  computed: {
    total() {
      return this.categories.length;
    },
    artistId() {
      return this.$route.params.id;
    },
  },
  methods: {
    handleClose() {
      this.dialogVisible.create = false;
      this.$emit("get:artist");
    },
    handleDelete(category_id) {
      this.$confirm("是否刪除該類別", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteArtistCategory(this.artistId, category_id);

          this.$message({
            type: "success",
            message: "刪除成功",
          });

          this.$emit("get:artist");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "刪除取消",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
