<template>
  <el-dialog
    title="新增類別"
    :visible.sync="dialogVisible"
    width="800px"
    :before-close="handleClose"
  >
    <el-form
      ref="categoryForm"
      :model="category_form"
      :rules="category_rules"
      label-position="top"
    >
      <el-form-item prop="category_id" label="類別">
        <el-select
          v-model="category_form.category_id"
          class="w-100"
          filterable
          remote
          :remote-method="handleSearchCategories"
        >
          <el-option
            v-for="{ category_id, name_zh, name_en } in categories"
            :key="category_id"
            :value="category_id"
            :label="name_zh"
          >
            <div style="float: left; width: 400px" class="text-overflow">
              {{ name_zh }}
            </div>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              name_en
            }}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="warning" @click="handleClose">取消</el-button>
      <el-button type="success" @click="handleSubmit">送出</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getCategories } from "@/api/category";
import { addArtistCategory } from "@/api/artist";

export default {
  name: "CreateCategoryDialog",
  props: {
    dialogVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      category_form: {
        category_id: "",
      },
      category_rules: {
        category_id: [
          { required: true, message: "請選擇藝術家", trigger: "blur" },
        ],
      },
      setup: {
        search: "",
      },
      categories: [],
    };
  },
  computed: {
    artistId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.handleGetCategories();
  },
  methods: {
    async handleGetCategories() {
      this.categories = await getCategories();
    },
    handleSearchCategories(query) {
      this.setup.search = query;

      this.handleGetCategories();
    },
    handleClose() {
      this.$emit("close:dialog");
    },
    handleSubmit() {
      this.$refs.categoryForm.validate(async (valid) => {
        if (valid) {
          await addArtistCategory(
            this.artistId,
            this.category_form.category_id
          );

          this.$message({
            type: "success",
            message: "創建成功",
          });

          this.handleClose();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
