import request from "@/utils/request";

/**
 * Get categories.
 */
export function getCategories() {
  return request({
    url: "api/v1/categories",
    method: "GET",
  });
}

/**
 * Get categories with pagination.
 *
 * @param {object} [params]
 * @param {number} params.limit
 * @param {number} params.page
 */
export function getCategoriesPagination(params) {
  return request({
    url: "api/v1/categories/page",
    method: "GET",
    params,
  });
}

/**
 * Get category.
 *
 * @param {number} category_id
 */
export function getCategory(category_id) {
  return request({
    url: `api/v1/categories/${category_id}`,
    method: "GET",
  });
}

/**
 * Create category.
 *
 * @param {object} data
 * @param {string} data.name_zh
 * @param {string} data.name_en
 */
export function createCategory(data) {
  return request({
    url: "api/v1/categories",
    method: "POST",
    data,
  });
}

/**
 * Update category.
 *
 * @param {number} category_id
 * @param {object} data
 * @param {string} data.name_zh
 * @param {string} data.name_en
 */
export function updateCategory(category_id, data) {
  return request({
    url: `api/v1/categories/${category_id}`,
    method: "PATCH",
    data,
  });
}

/**
 * Delete category.
 *
 * @param category_id
 */
export function deleteCategory(category_id) {
  return request({
    url: `api/v1/categories/${category_id}`,
    method: "DELETE",
  });
}
