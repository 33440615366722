<template>
  <div class="keyword-table-component">
    <div class="container">
      <table-title>
        <p>
          {{ title }}
          <span class="unit" v-if="showTotal">數量：{{ data.length }}</span>
        </p>

        <el-button type="success" @click="visibility.create = true"
          >新增關鍵字
        </el-button>
      </table-title>

      <el-table :data="data">
        <el-table-column
          label="ID"
          prop="keyword_id"
          sortable
        ></el-table-column>
        <el-table-column
          label="中文名稱"
          prop="name_zh"
          sortable
        ></el-table-column>
        <el-table-column
          label="英文名稱"
          prop="name_en"
          sortable
        ></el-table-column>
        <el-table-column label="編輯" fixed="right" width="100">
          <template v-slot="{ row }">
            <el-button
              type="danger"
              @click="handleDelete(row.keyword_id)"
              icon="el-icon-delete"
              size="mini"
              >刪除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-drawer
      title="新增關鍵字"
      :visible.sync="visibility.create"
      direction="rtl"
      size="600px"
      :before-close="handleCloseCreateDrawer"
    >
      <div class="keyword-wrapper">
        <el-table
          :key="visibility.create"
          ref="keywordTableRef"
          :data="adjustKeywords"
          @select="handleSelectionChange"
          @select-all="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="name_zh" label="關鍵字"></el-table-column>
          <el-table-column prop="name_en" label="英文名稱"></el-table-column>
        </el-table>

        <div class="flex-end" style="margin-top: 20px">
          <el-button type="primary" @click="handleSubmit">確定</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getKeywordsPagination } from "@/api/keyword";

const MAXIMUM_KEYWORDS_LIMIT = 10000;

export default {
  name: "KeywordTable",
  props: {
    data: {
      required: true,
      type: Array,
      default: () => [],
    },
    createMethod: {
      required: true,
      type: Function,
    },
    deleteMethod: {
      required: true,
      type: Function,
    },
    title: {
      required: true,
      type: String,
    },
    showTotal: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visibility: {
        create: false,
      },
      keywords: [],
      setup: {
        page: 0,
        limit: MAXIMUM_KEYWORDS_LIMIT,
        search: "",
      },
      keywordSelection: [],
    };
  },
  computed: {
    entityId() {
      return this.$route.params.id;
    },
    adjustKeywords() {
      return this.keywords.filter(
        (el) =>
          el.name_zh.toLowerCase().includes(this.setup.search.toLowerCase()) ||
          el.name_en.toLowerCase().includes(this.setup.search.toLowerCase())
      );
    },
  },
  watch: {
    "visibility.create"(val) {
      this.$nextTick(() => {
        if (val) {
          this.data.forEach(({ keyword_id }) => {
            this.$refs.keywordTableRef.toggleRowSelection(
              this.keywords.find(({ keyword_id: id }) => id === keyword_id),
              true
            );
          });
          this.keywordSelection = [...this.data];
        }
      });
    },
    adjustKeywords() {
      this.$nextTick(() => {
        if (this.visibility.create) {
          this.keywordSelection.forEach(({ keyword_id }) => {
            this.$refs.keywordTableRef.toggleRowSelection(
              this.keywords.find(({ keyword_id: id }) => id === keyword_id),
              true
            );
          });
        }
      });
    },
  },
  created() {
    this.handleGetKeywords();
  },
  methods: {
    async handleGetKeywords() {
      const { results } = await getKeywordsPagination(this.setup);

      this.keywords = results;
    },
    handleSelectionChange(val) {
      this.keywordSelection = val;
    },
    handleCloseCreateDrawer(done) {
      this.$confirm("確認關閉新增關鍵字抽屜？", "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    handleDelete(keywordId) {
      this.$confirm("確認刪除關鍵字？", "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await this.deleteMethod(this.entityId, keywordId);

          this.$emit("get:keywords");

          this.$message.success("刪除關鍵字成功");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消刪除",
          });
        });
    },
    async handleSubmit() {
      await this.createMethod(this.entityId, {
        keyword_id_group: this.keywordSelection.map(
          ({ keyword_id }) => keyword_id
        ),
      });

      this.$emit("get:keywords");

      this.$message.success("新增關鍵字成功");

      this.visibility.create = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.keyword-table-component {
  margin: 20px 0;

  .keyword-wrapper {
    padding: 0 10px 20px;

    .button-block {
      margin-top: 20px;
    }
  }
}
</style>
